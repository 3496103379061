<!--Vista de checkout de eventos -->
<template>
  <v-container v-if="eventExists" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="9">
        <v-row>
          <v-col cols="12" class="pt-10">
            <v-img v-if="msConfig.banner" class="rounded-xl" :src="msConfig.banner"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <p class="headline text-center font-weight-bold mb-0">
              {{ eventName }}
            </p>
          </v-col>
          <v-col cols="12">
            <p class="title text-center grey--text font-weight-bold" :class="published ? '' : 'mb-0'">
              Comprar accesos
            </p>
          </v-col>
          <v-col v-if="!published" cols="12" class="py-0">
            <p class="title red--text text-center font-weight-bold">
              Evento no disponible
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="px-3 pt-6 pb-2" rounded="xl" elevation="0"
              :style="`border: 1.7px solid ${msConfig.primaryColor}`" :disabled="!published">
              <v-row class="px-5 px-md-15">
                <!--INICIO de Paso 1 de Checkout-->
                <v-col v-if="currentStep == 1" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0 pt-10">
                      <p class="subtitle-1 mb-7">
                        <span class="font-weight-bold mr-4">
                          Paso {{ currentStep }} de 4
                        </span>
                        <span>
                          Selecciona la categoría del ticket y la cantidad que
                          quieres comprar
                        </span>
                      </p>
                    </v-col>
                    <v-col cols="12" class="access-item-border" v-for="ticket in tickets" :key="ticket.id">
                      <v-row>
                        <v-col cols="12" md="6" class="py-5 px-5 pl-md-10">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="#EFB810" v-on="on" v-bind="attrs" v-if="ticket.membersOnly">
                                mdi-brightness-percent
                              </v-icon>
                            </template>
                            <span>Precio exclusivo para miembros activos</span>
                          </v-tooltip>

                          <span class="title font-weight-bold">
                            {{ ticket.name }}
                          </span>
                          <p class="body-2">{{ ticket.details }}</p>
                          <p v-if="hideAccesscounter(ticket.event, ticket)" class="subtitle-2 mb-0" :class="ticket.availableTicketsForSale == 0
                            ? 'red--text text--darken-1 font-weight-bold'
                            : ''
                            ">
                            {{
                              ticket.availableTicketsForSale != 0
                                ? ticket.availableTicketsForSale +
                                " accesos disponibles."
                                : "Accesos agotados."
                            }}
                          </p>
                          <p>
                            <span v-if="(!user.role ||
                              (user.role != 'MEMBER' &&
                                user.role != 'MEMBERSHIP_USER')) &&
                              ticket.membersOnly
                            " class="red--text text--darken-1 font-weight-bold subtitle-2">
                              Para poder comprar este boleto es necesario ser
                              miembro activo. Si ya eres miembro activo,
                              <router-link to="/login" class="red--text text--darken-1 font-weight-bold subtitle-2">
                                inicia sesión
                              </router-link>
                              para obtener tu precio especial.
                            </span>
                          </p>
                        </v-col>
                        <v-col cols="12" md="2" xl="3" offset-md="1" class="py-5 px-5">
                          <p class="title font-weight-bold mb-0">Precio</p>
                          <p v-if="paymentModality" class="subtitle-1">
                            GRATIS
                          </p>
                          <p v-else class="subtitle-1">
                            {{ formatNumber(ticket.price) }}
                          </p>
                        </v-col>
                        <v-col cols="12" md="3" xl="2" class="px-5 pr-md-10">
                          <v-row>
                            <v-col cols="12" class="pb-1">
                              <p class="title text-center font-weight-bold mb-0">
                                Cantidad:
                              </p>
                            </v-col>
                          </v-row>
                          <v-row justify="center" align="center">
                            <v-col cols="4" sm="3" class="d-flex justify-center">
                              <v-btn fab x-small color="primary" depressed :disabled="(!user.role ||
                                (user.role != 'MEMBER' &&
                                  user.role != 'MEMBERSHIP_USER')) &&
                                ticket.membersOnly
                                " @click="removeTicket(ticket.id)">
                                <v-icon>fas fa-minus</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="4" sm="5" class="d-flex text-center justify-center">
                              <span class="title font-weight-bold">
                                {{
                                  counterValues[ticket.id]
                                    ? counterValues[ticket.id]
                                    : 0
                                }}
                              </span>
                            </v-col>
                            <v-col cols="4" sm="3" class="d-flex justify-center">
                              <v-btn fab x-small color="primary" :disabled="((!user.role ||
                                (user.role != 'MEMBER' &&
                                  user.role != 'MEMBERSHIP_USER')) &&
                                ticket.membersOnly) ||
                                ticket.availableTicketsForSale === 0
                                " depressed @click="addTicket(ticket)">
                                <v-icon>fas fa-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="mt-3">
                      <p>
                        {{ getLabelForAccess(maxTicketsUserCanBuy) }}
                      </p>
                    </v-col>
                    <!--Ocultar apartado del cupón si el evento es gratuito-->
                    <v-col v-if="!paymentModality" cols="12">
                      <v-row align="center">
                        <v-col cols="12" md="6" lg="4">
                          <v-tooltip top :disabled="!ticketsToBuyIsZero">
                            <template v-slot:activator="{ on }">
                              <v-row v-on="on">
                                <v-text-field class="mr-2" v-model="coupon" :disabled="ticketsToBuyIsZero || couponApplied
                                  " label="Cupón/Cortesía" required dense filled rounded :error="errors.coupon.error"
                                  :error-messages="errors.coupon.message" :success="success.coupon.success"
                                  :success-messages="success.coupon.message" prepend-inner-icon="fas fa-tags"
                                  v-on:keyup.enter="addCoupon" />
                              </v-row>
                            </template>
                            <span>
                              Para poder ingresar un(a) cupón/cortesía es
                              necesario seleccionar un boleto.
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-col>
                          <v-btn @click="addCoupon" :disabled="ticketsToBuyIsZero || couponApplied" color="secondary"
                            small>
                            Aplicar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="my-7">
                      <v-row>
                        <v-btn class="mx-1" color="primary" text rounded
                          :to="{ name: 'microsite', params: { eventId } }">
                          <span class="font-weight-bold">Cancelar</span>
                        </v-btn>
                        <v-btn class="mx-1" :color="msConfig.buttonColor" :style="`color: ${msConfig.textButtonColor}`"
                          rounded :disabled="ticketsToBuyIsZero" @click="currentStep++">
                          <span class="font-weight-bold"> Continuar </span>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-end align-end pb-7">
                      <p v-if="paymentModality" class="headline mb-0">
                        <span class="font-weight-bold">Evento gratuito</span>
                      </p>
                      <p v-else class="headline mb-0">
                        <span class="font-weight-bold">Total: </span>{{ totalPurchaseWithDiscount.string }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <!--FIN de Paso 1 de Checkout-->
                <!--INICIO de Paso 2 de Checkout-->
                <v-col v-if="currentStep === 2" cols="12">
                  <v-form ref="ticketsForm" v-model="validTicketsForm">
                    <v-row>
                      <v-col cols="12" class="pb-0 pt-10">
                        <p class="subtitle-1 mb-7">
                          <span class="font-weight-bold mr-4">
                            Paso {{ currentStep }} de 4
                          </span>
                          <span> Ingresa los datos de asistente </span>
                        </p>
                      </v-col>
                      <!--INICIO de v-expansion-panels para poner los datos de asistente-->
                      <v-col cols="12 pt-0 mb-5">
                        <v-expansion-panels multiple v-model="panel" flat>
                          <v-expansion-panel class="elevation-1" v-for="(ticket, index) in order.tickets" :key="index">
                            <v-expansion-panel-header>
                              <p class="title font-weight-bold mb-0">
                                {{ ticket.name }}
                              </p>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col cols="12">
                                  <p class="font-weight-bold">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Verifica que tu email sea correcto, es ahí
                                    donde enviaremos tu confirmación de
                                    registro.
                                  </p>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field v-model="ticket.attendeeInformation.name" label="Nombre(s)"
                                    :rules="fieldsValidations.name" filled rounded></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field v-model="ticket.attendeeInformation.lastName
                                    " label="Apellidos" :rules="fieldsValidations.lastName" filled
                                    rounded></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field v-model="ticket.attendeeInformation.email" label="E-mail"
                                    :rules="fieldsValidations.email" @keydown="formatEmail(ticket)" filled
                                    rounded></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field v-model="ticket.attendeeInformation.confirmEmail
                                    " :rules="[
                                      (ticket.attendeeInformation.email ===
                                        ticket.attendeeInformation
                                          .confirmEmail &&
                                        ticket.attendeeInformation.email !==
                                        '' &&
                                        ticket.attendeeInformation
                                          .confirmEmail !== '') ||
                                      'La confirmación de e-mail no es correcta',
                                    ]" @keydown="formatEmail(ticket)" label="Confirmar e-mail" required filled
                                    rounded />
                                </v-col>
                                <!-- EXTRA FIELDS -->
                                <template v-if="ticket.registrationFields.length > 0">
                                  <v-col cols="12" sm="6" md="4" v-for="field in ticket.registrationFields"
                                    :key="field._id">
                                    <v-text-field v-if="field.fieldType === 'TEXT'" v-model="ticket.extraInfo[field.formattedName]
                                      " :rules="field.required
                                        ? fieldsValidations.required
                                        : []
                                        " :hint="field.description" filled rounded>
                                      <template v-slot:label>
                                        <span class="label_move">{{
                                          field.name
                                        }}</span>
                                      </template>
                                    </v-text-field>
                                    <v-select v-if="field.fieldType === 'LIST'" v-model="ticket.extraInfo[field.formattedName]
                                      " :rules="field.required
                                        ? fieldsValidations.required
                                        : []
                                        " :menu-props="{
                                          zIndex: 100,
                                          top: true,
                                          offsetY: true,
                                        }" :hint="field.description" :items="field.name == 'Ejecutivo'
                                          ? executivesFiltered(field.options)
                                          : field.options
                                          " filled rounded>
                                      <template v-slot:label>
                                        <span class="label_move">{{
                                          field.name
                                        }}</span>
                                      </template>

                                      <template v-slot:prepend-item v-if="field.name == 'Ejecutivo'">
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-text-field v-model="searchExecutive" placeholder="Search"></v-text-field>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-1"></v-divider>
                                      </template>
                                    </v-select>
                                    <v-file-input v-if="field.fieldType == 'DOC' ||
                                      field.fieldType == 'IMAGE'
                                    " v-model="ticket.extraInfo[field.formattedName]
                                      " :rules="field.fieldType == 'IMAGE'
                                        ? fileImageRules
                                        : field.fieldType == 'DOC'
                                          ? fileRules
                                          : ''
                                        " show-size :hint="field.description" prepend-icon=""
                                      prepend-inner-icon="$file" persistent-hint filled rounded>
                                      <template v-slot:label>
                                        <span class="label_move">{{
                                          field.name
                                        }}</span>
                                      </template>
                                    </v-file-input>
                                  </v-col>
                                </template>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <!--FIN de v-expansion-panels para poner los datos de asistente-->
                      <v-col cols="12" md="6" class="my-7">
                        <v-row>
                          <v-btn class="mx-1" color="primary" text rounded @click="currentStep--"><span
                              class="font-weight-bold">Regresar</span></v-btn>
                          <v-btn class="mx-1" :color="msConfig.buttonColor"
                            :style="`color: ${msConfig.textButtonColor}`" rounded @click="setAttendeeInformation">
                            <span class="font-weight-bold">Continuar</span>
                          </v-btn>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex justify-end align-end pb-7">
                        <p v-if="paymentModality" class="headline mb-0">
                          <span class="font-weight-bold">Evento gratuito</span>
                        </p>
                        <p v-else class="headline mb-0">
                          <b>Total: </b>{{ totalPurchaseWithDiscount.string }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
                <!--FIN de Paso 2 de Checkout-->
                <!--INICIO de Paso 3 de Checkout-->
                <v-col v-if="currentStep === 3" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0 pt-10">
                      <p class="subtitle-1 mb-7">
                        <span class="font-weight-bold mr-4">Paso {{ currentStep }} de 4 </span><span>Selecciona el
                          método de pago</span>
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" class="pt-0">
                          <v-item-group v-model="order.paymentInformation.paymentMethod">
                            <v-row justify="center">
                              <v-col cols="12">
                                <h2 class="title font-weight-bold">
                                  Métodos de pago
                                </h2>
                              </v-col>
                              <template v-if="paymentMethods.length > 0">
                                <v-col cols="12" sm="6" md="3" v-for="(item, index) in paymentMethods" :key="index">
                                  <v-item v-if="item.name !== 'BANK_TRANSFER'
                                    ? true
                                    : item.name === 'BANK_TRANSFER' &&
                                    (hasStripeConfig ||
                                      user.stripeAccountType == 'EXPRESS')
                                  " v-slot="{ active, toggle }" :value="item.name">
                                    <v-card :color="active
                                      ? msConfig.buttonColor
                                      : 'elementBackGray'
                                      " class="pa-2 px-lg-3 py-lg-5 fill-height text-center" :style="`color: ${active ? msConfig.textButtonColor : ''
                                        }`" @click="
                                          paymentSelection(toggle, item.name)
                                          " rounded="xl" :elevation="active ? '2' : '0'">
                                      <v-row justify="center" align="center" class="fill-height">
                                        <v-col cols="12">
                                          <h2 class="title font-weight-bold mb-5">
                                            {{ setPaymentMethod(item.name) }}
                                          </h2>
                                          <v-icon :style="getSizePaymentsMethodsIcons" :color="active
                                            ? msConfig.textButtonColor
                                            : ''
                                            ">{{ item.icon }}</v-icon>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-item>
                                </v-col>
                              </template>
                              <v-col v-else>
                                <h3 class="red--text text-center title font-weight-bold">
                                  No se encontraron métodos de pago
                                </h3>
                              </v-col>
                            </v-row>
                          </v-item-group>
                        </v-col>
                        <v-col v-if="order.paymentInformation.paymentMethod ===
                          paymentMethodsKeys.Cash
                        " cols="12" class="pb-0">
                          <p class="subtitle-1 primary--text font-weight-bold">
                            <v-icon color="amber">mdi-alert</v-icon> Los pagos
                            en OXXO se ven reflejados 24 horas después de haber
                            realizado el pago, por lo que recomendamos hacer el
                            pago a mas tardar 24 horas antes de que comience el
                            evento.
                          </p>
                          <!--<p class="subtitle-1">Los pagos en oxxo se ven reflejados 24 horas después de haber realizado el pago, por lo que recomendamos hacer el pago, a mas tardar 24 horas antes de que comience el evento.</p>-->
                        </v-col>

                        <v-col v-if="order.paymentInformation.paymentMethod ===
                          paymentMethodsKeys.BankTransfer
                        " cols="12" class="pb-0">
                          <p class="subtitle-1 primary--text font-weight-bold" color="red">
                            <v-icon color="amber">mdi-alert</v-icon> Los pagos
                            mediante transferencia bancaria se ven reflejados 24
                            horas después de haber realizado el pago, por lo que
                            recomendamos hacer el pago a mas tardar 24 horas
                            antes de que comience el evento.
                          </p>
                          <p class="subtitle-1 font-weight-bold" style="color: red">
                            <v-icon color="amber">mdi-alert</v-icon> Esta CLABE
                            interbancaria es DESECHABLE, única y exclusiva para
                            esta transacción, favor de no compartirla, ni
                            guardarla en la App de tu banco. Al terminar la
                            operación se INVALIDARÁ y NO se podrá usar más. Si
                            la vuelves a usar tu dinero se podría perder.
                          </p>

                          <!--<p class="subtitle-1">Los pagos en oxxo se ven reflejados 24 horas después de haber realizado el pago, por lo que recomendamos hacer el pago, a mas tardar 24 horas antes de que comience el evento.</p>-->
                        </v-col>
                        <v-col cols="12" class="pt-0 mt-5">
                          <v-row justify="center">
                            <v-col cols="12">
                              <h3 class="title font-weight-bold">
                                Información del comprador
                              </h3>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field label="Nombre(s)" v-model="order.paymentInformation.name" required
                                prepend-inner-icon="fas fa-user" filled rounded />
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field label="Apellidos" v-model="order.paymentInformation.lastName" required
                                prepend-inner-icon="fas fa-user" filled rounded />
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field label="E-mail" v-model="order.paymentInformation.email" required
                                prepend-inner-icon="fas fa-envelope" filled rounded />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="order.paymentInformation.paymentMethod ===
                          paymentMethodsKeys.CreditCard
                        " cols="12" class="pt-0 mt-5">
                          <v-row justify="center">
                            <v-col cols="12">
                              <h3 class="title font-weight-bold">
                                Información de la tarjeta
                              </h3>
                            </v-col>
                            <v-col cols="12" md="12" sm="6">
                              <v-text-field v-model="order.paymentInformation.cardName" type="text" name="name"
                                label="Nombre(s) del titular" required prepend-inner-icon="fas fa-user" filled
                                rounded />
                            </v-col>
                            <v-col cols="12">
                              <v-stripe-card v-model="source" :api-key="apiKey" :stripeAccount="stripeAccount"
                                :hidePostalCode="true" :key="stripeComponentKey"
                                :disabled="!order.paymentInformation.cardName" ref="stripeRef" />
                            </v-col>
                            <v-col v-if="false" cols="12" class="pt-0">
                              <v-switch label="Quiero pagar con meses sin intereses." :color="msConfig.primaryColor"
                                hide-details class="mt-0"></v-switch>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="installments.plans.length > 0 &&
                          order.paymentInformation.paymentMethod ===
                          paymentMethodsKeys.CreditCard
                        " cols="12" class="pt-0 mt-3">
                          <v-row justiify="center">
                            <v-col cols="12" md="12" sm="6">
                              <h3 class="title font-weight-bold">
                                Meses sin intereses
                              </h3>
                            </v-col>
                            <v-col cols="12">
                              <v-card>
                                <v-list>
                                  <v-list-item-group v-model="installments.selected">
                                    <v-list-item v-for="(item, i) in installments.plans" :key="i" :value="item">
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.count }}
                                          {{
                                            item.interval === "month"
                                              ? "meses"
                                              : "N/A"
                                          }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-list>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="pb-0" v-if="this.tickets[0].event !== '650b6097df1055001b68aa77'
                        ">
                          <h2 class="title font-weight-bold">Facturación</h2>
                        </v-col>
                        <v-col cols="12" class="d-flex pt-0" v-if="this.tickets[0].event !== '650b6097df1055001b68aa77'
                        ">
                          <v-checkbox hide-details @click="declineBill = false" class="mr-4" v-model="acceptBill"
                            :color="msConfig.active">
                            <template v-slot:label>
                              <span class="d-flex align-center pa-0">Quiero facturar mi compra</span>
                            </template>
                          </v-checkbox>
                          <v-checkbox hide-details class="mr-3" @click="acceptBill = false" v-model="declineBill"
                            :color="msConfig.active">
                            <template v-slot:label>
                              <span class="d-flex align-center pa-0">No quiero facturar mi compra</span>
                            </template>
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" class="py-0 d-flex" v-if="this.tickets[0].event !== '650b6097df1055001b68aa77'
                        ">
                          <p v-if="acceptBill">
                            <span v-if="[
                              '62b622c66a7441ae20159d7d',
                              '6297b0fec7bf8b6f826313be',
                            ].includes(organizationId)
                            ">
                              <v-icon color="red">mdi-alert</v-icon>
                              Tendrás hasta el final del día de hoy (11:59 pm)
                              para generar tu factura usando el ID que recibirás
                              en el correo de confirmación.
                            </span>

                            <span v-else>
                              <b>Nota:</b> Tendrás hasta fin de mes para generar
                              tu factura usando el ID que recibirás en el correo
                              de confirmación
                            </span>
                          </p>
                          <p v-else-if="declineBill">
                            <b>Atención:</b> si eliges esta opción debes estar
                            seguro, ya que no podrás facturar esta compra en el
                            futuro.
                          </p>
                          <p v-else>
                            * Debe seleccionar una opción para continuar
                          </p>
                        </v-col>
                        <!---->

                        <v-col cols="12" class="pt-0" v-if="[
                          '62b622c66a7441ae20159d7d',
                          '6297b0fec7bf8b6f826313be',
                        ].includes(organizationId)
                        ">
                          <v-checkbox v-model="billingPolitics.terms" :color="msConfig.active" hide-details>
                            <template v-slot:label>
                              <span>
                                Acepto las&nbsp;
                                <router-link to="/politicas-de-facturacion" v-slot="{ href }">
                                  <a :href="href" target="_blank" @click.stop>Políticas de Facturación.
                                  </a>
                                </router-link>
                              </span>
                            </template>
                          </v-checkbox>
                        </v-col>
                        <!---->

                        <v-col cols="12" class="pt-0">
                          <v-checkbox v-model="termsOfUSe.terms" :color="msConfig.active" hide-details>
                            <template v-slot:label>
                              <span>
                                Acepto los&nbsp;
                                <router-link to="/terminos-y-condiciones" v-slot="{ href }">
                                  <a :href="href" target="_blank" @click.stop>Términos y Condiciones
                                  </a>
                                </router-link>
                                &nbsp;y el&nbsp;
                                <router-link to="/aviso-de-privacidad" v-slot="{ href }">
                                  <a :href="href" target="_blank" @click.stop>Aviso de Privacidad.</a>
                                </router-link>
                              </span>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="my-7">
                      <v-row>
                        <v-btn class="mx-1" color="primary" text rounded @click="
                          paymentModality
                            ? (currentStep = currentStep - 2)
                            : currentStep--
                          ">
                          <span class="font-weight-bold">Regresar</span>
                        </v-btn>
                        <v-btn class="mx-1" :color="msConfig.buttonColor" :style="`color: ${msConfig.textButtonColor}`"
                          rounded @click="executePaymentAction" :disabled="disableContinueBuyButton">
                          <span class="font-weight-bold">Continuar</span>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-end align-end pb-7">
                      <p v-if="paymentModality" class="headline mb-0">
                        <span class="font-weight-bold">Evento gratuito</span>
                      </p>
                      <p v-else class="headline mb-0">
                        <span class="font-weight-bold">Total: </span>{{ totalPurchaseWithDiscount.string }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <!--FIN de Paso 3 de Checkout-->
                <!--INICIO de Paso 4 de Checkout-->
                <v-col v-if="currentStep == 4" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0 pt-10">
                      <p class="subtitle-1 mb-7">
                        <span class="font-wordereight-bold mr-4">Paso {{ currentStep }} de 4 </span><span>Revisa los
                          datos de tu
                          compra</span>
                      </p>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <p class="headline font-weight-bold">Resumen</p>
                    </v-col>
                    <v-col v-if="order.items.length > 0" cols="12">
                      <!-- <v-row class="text-center" justify="center">
                        <v-col cols="12" md="6">
                          <h2 class="title font-weight-bold">Tipo de acceso</h2>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Precio U.</h2>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Cantidad</h2>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Subtotal</h2>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Facturación</h2>
                        </v-col>
                      </v-row> -->
                      <v-row v-for="ticket in order.items" :key="ticket.id" class="text-center" justify="center">
                        <v-col cols="6" md="2">
                          <h2 class="title font-weight-bold">Tipo de acceso</h2>
                          <p class="subtitle-1 mb-0">
                            {{ ticket.name }}
                          </p>
                        </v-col>
                        <v-col cols="6" md="2">
                          <h2 class="title font-weight-bold">Precio U.</h2>
                          <p class="subtitle-1 mb-0">
                            {{ formatNumber(ticket.price) }}
                          </p>
                        </v-col>
                        <v-col cols="6" md="2">
                          <h2 class="title font-weight-bold">Cantidad</h2>
                          <p class="subtitle-1 mb-0">
                            {{ ticket.quantity }}
                          </p>
                        </v-col>
                        <v-col cols="6" md="2">
                          <h2 class="title font-weight-bold">Subtotal</h2>
                          <p class="subtitle-1 mb-0">
                            {{ formatNumber(ticket.quantity * ticket.price) }}
                          </p>
                        </v-col>
                        <v-col cols="6" md="2">
                          <h2 class="title font-weight-bold">Facturación</h2>
                          <p class="subtitle-1 mb-0">
                            {{
                              order.paymentInformation.needsInvoice
                                ? "Si"
                                : "No"
                            }}
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row v-if="order.coupon.id" class="text-center" align="center" justify="start">
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Cupón Aplicado</h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Porcentaje de Descuento
                          </h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Cantidad de Descuento
                          </h2>
                        </v-col>
                      </v-row>
                      <v-row v-if="order.coupon.id" class="text-center" justify="start">
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ order.coupon.code }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ order.coupon.percentOff }}%
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ discount.string }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6" class="my-9">
                      <v-row>
                        <v-btn class="mx-1" color="primary" text rounded @click="returnFromStepFour"><span
                            class="font-weight-bold">Regresar</span></v-btn>
                        <v-btn class="mx-1" :color="msConfig.buttonColor" :style="`color: ${msConfig.textButtonColor}`"
                          rounded @click="finishBuying">
                          <span class="font-weight-bold">Finalizar</span>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-end align-end pb-7">
                      <p class="headline mb-0">
                        <span class="font-weight-bold">Total: </span>{{ totalPurchaseWithDiscount.string }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <!--FIN de Paso 4 de Checkout-->
                <!--INICIO de última sección del Checkout-->
                <v-col v-if="currentStep == 5" cols="12" class="pb-10">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-row justify="center" class="mb-5">
                        <v-icon :style="getSizeFinalIcon">mdi-email-newsletter</v-icon>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="text-center pt-0">
                      <p class="headline font-weight-bold text-uppercase">
                        ¡Muchas gracias!
                      </p>
                      <!-- Para pagos con tarjeta, que usaron cupón/cortesía del 100% o el boleto costaba $0.00 -->
                      <p v-if="totalPurchaseWithDiscount.number === 0 ||
                        order.paymentInformation.paymentMethod ===
                        paymentMethodsKeys.CreditCard
                      " class="subtitle-1 mb-3">
                        El registro se ha realizado exitosamente, consulte su
                        correo electrónico para obtener sus acceso(s).
                      </p>
                      <!-- Para referencias de SPEI y OXXO -->
                      <p v-else class="subtitle-1 mb-3">
                        El registro se ha realizado exitosamente, consulte su
                        correo electrónico para obtener su referencia de pago.
                      </p>
                      <!-- Para cualquier tipo de registro -->
                      <p class="subtitle-1 mb-3" style="color: #17c6e9">
                        En ocasiones tu configuración de seguridad envía algunos
                        correos a SPAM, no olvides revisar ahí.
                      </p>
                      <p v-if="order.coupon.percentOff != 100 &&
                        !paymentModality &&
                        order.paymentInformation.needsInvoice
                      " class="subtitle-1">
                        El id de tu orden es el siguiente <b>{{ order.id }}</b>, te recomendamos guardarlo, ya que te
                        servirá para
                        generar la factura de tu compra. <br />
                        Puedes generar la factura haciendo click en el botón a
                        continuación.
                      </p>
                      <div v-if="order.coupon.percentOff != 100 &&
                        !paymentModality &&
                        order.paymentInformation.needsInvoice
                      ">
                        <v-btn large class="mx-1 font-weight-bold my-2 mb-6 pa-5" :color="msConfig.buttonColor"
                          :style="`color: ${msConfig.textButtonColor}`" target="_blank"
                          :href="`/facturacion-cliente/${order.id}`" rounded>
                          Generar Factura
                        </v-btn>
                      </div>
                      <p v-if="[
                        '62b622c66a7441ae20159d7d',
                        '6297b0fec7bf8b6f826313be',
                      ].includes(organizationId) &&
                        order.coupon.percentOff != 100 &&
                        !paymentModality &&
                        order.paymentInformation.needsInvoice
                      " class="subtitle-1 mb-3">
                        <v-icon color="red">mdi-alert</v-icon>
                        Tendrás hasta el final del día de hoy (11:59 pm) para
                        generar tu factura usando el ID que recibirás en el
                        correo de confirmación.
                      </p>
                      <p class="headline text-uppercase font-weight-bold"
                        :class="order.total === '0' ? 'text-center' : ''">
                        Resumen
                      </p>
                      <h4 v-if="order.paymentInformation.paymentMethod ===
                        paymentMethodsKeys.BankTransfer
                      " style="color: #ff0000">
                        Recuerda que tienes hasta las {{ dateExpireOrder }} para
                        realizar tu pago.
                      </h4>
                    </v-col>
                    <v-col v-if="order.items.length > 0" cols="12">
                      <v-row class="text-center" justify="center">
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Tipo de acceso</h2>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Precio U.</h2>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Cantidad</h2>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Subtotal</h2>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <h2 class="title font-weight-bold">Facturación</h2>
                        </v-col>
                      </v-row>
                      <v-row v-for="(ticket, index) in order.items" :key="ticket.id" class="text-center"
                        justify="center">
                        <v-col cols="6" sm="2">
                          <p class="subtitle-1 mb-0">
                            {{ ticket.name }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <p class="subtitle-1 mb-0">
                            {{ formatNumber(ticket.price) }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <p class="subtitle-1 mb-0">
                            {{ ticket.quantity }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <p class="subtitle-1 mb-0">
                            {{ formatNumber(ticket.quantity * ticket.price) }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <p class="subtitle-1 mb-0">
                            {{
                              order.paymentInformation.needsInvoice
                                ? "Si"
                                : "No"
                            }}
                          </p>
                        </v-col>
                        <v-col v-if="index !== order.items.length - 1" cols="12">
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row v-if="order.coupon.id" class="text-center" align="center" justify="start">
                        <v-col cols="12">
                          <v-divider />
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">Cupón Aplicado</h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Porcentaje de Descuento
                          </h2>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <h2 class="title font-weight-bold">
                            Cantidad de Descuento
                          </h2>
                        </v-col>
                      </v-row>
                      <v-row v-if="order.coupon.id" class="text-center" justify="start">
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ order.coupon.code }}
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ order.coupon.percentOff }}%
                          </p>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <p class="subtitle-1 mb-0">
                            {{ discount.string }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <!--FIN de última sección del Checkout-->
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-10">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-img max-width="150" height="auto" src="@/assets/images/weChamber/poweredby.svg"></v-img>
      </v-col>
    </v-row>
    <!---------------------------------------DIALOGS DE CHECKOUT--------------------------------------------->
    <v-dialog v-model="iframeDialog" persistent max-width="700">
      <v-card>
        <iframe v-if="iframeSource" :src="iframeSource" width="100%" height="400" frameborder="0">
        </iframe>
      </v-card>
    </v-dialog>

    <!--Dialogs de facturación-->
    <v-dialog v-model="facturaDialog" persistent max-width="400">
      <v-card>
        <v-card-text class="px-1 rounded-xl">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" class="pt-8">
                <v-row justify="center">
                  <v-icon :color="msConfig.primaryColor" :style="getBigSize">fas fa-question-circle</v-icon>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center">
                <h2 class="headline font-weight-bold">
                  ¿Cómo obtengo mi factura?
                </h2>
                <p>
                  Solo debes registrar tus datos fiscales en este apartado.
                  Recibirás de forma automática tu factura vía correo
                  electrónico una vez finalizada tu compra.
                </p>
                <v-row justify="center" class="pt-3">
                  <v-btn color="primary" @click="(factura = true), (facturaDialog = false)" rounded>Aceptar</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-template :open="isOpenErrorCardModal" width="600" :secondaryBtnProps="{
      show: true,
      text: 'Cerrar',
      textColor: 'white',
      color: 'accent',
    }" @close="closeErrorCardModal">
      <template #content>
        <v-row justify="center">
          <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
            <v-img class="" src="@/assets/images/weChamber/logo-WC-microsite.svg" max-width="60" height="auto"
              contain></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-2">
            <p class="text-center font-weight-bold title mb-0">
              No se ha podido finalizar el pago
            </p>
          </v-col>
          <v-col cols="12" class="py-0">
            <p class="subtitle-1 text-center mb-0">{{ errorMessage }}</p>
          </v-col>
          <v-col cols="12" class="pt-0">
            <p class="subtitle-1 text-center mb-0 secondary--text font-weight-bold">
              {{ errorCode }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="errorCode != ''">
          <v-col cols="12">
            <p class="subtitle-1 text-center">
              Para más información sobre el código de error, puede ingresar al
              siguiente link:
              <a href="https://stripe.com/docs/error-codes" target="_blank">Códigos de error de pago con tarjetas</a>
            </p>
          </v-col>
        </v-row>
      </template>
    </modal-template>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="'error'" right top>
      <v-icon color="white">mdi-domain</v-icon>

      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <PersonalizedDialog :dialog="voucherDialog" @save="closeVoucherDialog" hide-name hide-button hide-image>
      <template #alertContent>
        <voucherCard :instructions="bankTransferInstructions" :description="bankTransferDescription"></voucherCard>
      </template>
      <template #button>
        Finalizar
      </template>
    </PersonalizedDialog>
  </v-container>
  <!--Mostrar este apartado si no existe el evento que se quiere mostrar-->
  <v-container v-else-if="!loaderVisible && !eventExists" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-row justify="center" align="center" class="mt-10 text-center">
          <v-col cols="12" sm="4" lg="5">
            <v-row justify="center">
              <v-img src="@/assets/images/weChamber/imagotype-WeChamber.jpg" width="100%" height="auto" contain></v-img>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-10">
            <h1 class="display-1 font-weight-bold">
              ¡El evento que buscas no se encontró!
            </h1>
          </v-col>
          <v-col cols="12">
            <h1 class="display-12">404</h1>
          </v-col>
          <v-col cols="12">
            <v-btn class="font-weight-bold" x-large color="secondary" rounded to="/">
              Ir al inicio
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//import VStripeCard from "v-stripe-elements/lib/VStripeCard";
import moment from "moment";
import VStripeCard from "@/components/v-stripe-elements/lib/VStripeCard";
import { validations } from "@/share/validations";
import Endpoints from "@/share/Endpoints";
import { formatPrice } from "@/utils";
import { mapState, mapMutations } from "vuex";
import CardErrors from "@/share/cardErrorCodes";
import ModalTemplate from "@/components/shared/ModalTemplate";
import BillingTerms from "../../components/dashboard/billing/billingTerms.vue";
import PersonalizedDialog from "../../components/shared/PersonalizedDialog.vue";
import voucherCard from "../../components/shared/customerInstructions/voucherCard.vue";

export default {
  components: {
    ModalTemplate,
    VStripeCard,
    PersonalizedDialog,
    voucherCard,
    BillingTerms,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchExecutive: "",
      apiKey: process.env.VUE_APP_STRIPE_API_KEY,
      iframeSource: "",
      stripeAccount: "",
      organizationId: "",
      hasStripeConfig: false,
      acceptBill: false,
      declineBill: false,
      eventExists: false,
      source: null,
      validTicketsForm: false,
      currentStep: 1,
      voucherDialog: false,
      bankTransferInstructions: {},
      bankTransferDescription: '',
      coupon: "",
      couponApplied: false,
      panel: [0],
      termsOfUSe: {
        terms: false,
        receiveInfo: false,
      },
      billingPolitics: {
        terms: false,
        receiveInfo: false,
      },
      fieldsValidations: {
        name: validations.nameValidation({
          required: true,
        }),
        lastName: validations.nameValidation({
          name: "apellidos",
          required: true,
        }),
        email: validations.emailValidation({
          required: true,
        }),
        required: validations.requiredMValidation(),
      },
      errors: {
        coupon: {
          error: false,
          message: "",
        },
      },
      success: {
        coupon: {
          success: false,
          message: "",
        },
      },
      factura: false,
      facturaDialog: false,
      iframeDialog: false,
      eventName: "",
      paymentModality: false,
      published: false,
      ticketsToBuy: 0,
      maxTicketsUserCanBuy: 5,
      msConfig: {
        banner: "",
        primaryColor: "",
        buttonColor: "",
        textButtonColor: "white",
      },
      tickets: [],
      paymentMethods: [],
      hideEventAccess: [
        "650342d7f0dcd5001bca42ff", //new
        "646510f1372b0b001be1a673",
        "64650ea0372b0b001be1a5b5",
        "64650dfe84e61b001aaa8ecb",
      ],
      paymentMethodsKeys: {
        Free: "FREE",
        Cash: "CASH",
        CreditCard: "CREDIT_CARD",
        BankTransfer: "BANK_TRANSFER",
      },
      installments: {
        selected: null,
        plans: [],
      },
      // Objecto que guardará los datos de la transacción
      order: {
        coupon: {
          id: "",
          percentOff: 0,
          limit: null,
          endDate: "",
          applications: 0,
        },
        id: "",
        items: [],
        paymentInformation: {
          name: "",
          lastName: "",
          email: "",
          paymentMethod: null,
          needsInvoice: false,
          cardName: "",
        },
        tickets: [],
      },
      cardPaymentIntentId: "",
      total: 0,
      snackbar: false,
      snackbarText: "PlaceHolder",
      stripeComponentKey: 0,
      clientSecret: "",
      errorMessage: "",
      errorCode: "",
      isOpenErrorCardModal: false,
      fileRules: validations.fileValidation(),
      fileImageRules: validations.imageFileValidation(),
    };
  },
  watch: {
    currentStep(current) {
      if (current === 3) {
        const { name, lastName, email } = this.order.paymentInformation;
        const { attendeeInformation } = this.order.tickets[0];
        !name &&
          (this.order.paymentInformation.name = attendeeInformation.name);
        !lastName &&
          (this.order.paymentInformation.lastName =
            attendeeInformation.lastName);
        !email &&
          (this.order.paymentInformation.email = attendeeInformation.email);
        this.cardPaymentIntentId = "";
      }
    },
    source: async function () {
      try {
        this.loading();
        const { card, stripe } = this.$refs.stripeRef;
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            name: this.order.paymentInformation.cardName,
            email: this.order.paymentInformation.email,
          },
        });
        const { data } = await this.axios({
          method: "post",
          url: Endpoints.cardPayementIntent,
          data: {
            paymentMethod: { id: paymentMethod.id, total: this.total },
            orderId: this.order.id,
            cardCountry: paymentMethod.card.country,
          },
        });

        this.installments.plans = data.paymentIntent.availablePlans;

        if (this.eventId == '6626b791a9e76a001ce4d142') {
          this.installments.plans = [
            {
              count: 3,
              interval: "month",
              type: "fixed_count"
            },
            {
              count: 6,
              interval: "month",
              type: "fixed_count"
            }
          ]
        }


        this.cardPaymentIntentId = data.paymentIntent.id;
      } catch (error) {
        // this.errorMessage = error;
        // this.isOpenErrorCardModal = true;
        console.log("******** Card validation ***********");
        if (
          error.response.data.error.message ==
          "Por favor revise la información de los asistentes"
        ) {
          this.errorMessage =
            "Por favor revise la información de los asistentes";
          this.isOpenErrorCardModal = true;
          this.currentStep--;
        }
        console.log(error.response.data);
      } finally {
        this.loaded();
      }
    },
  },
  async created() {
    console.log(CardErrors["stop_payment_order"]);
    //console.log(CardErrors);
    this.loading();
    await this.setEventInfo();
    await this.createOrder();
    this.window.addEventListener("message", this.validate3sSecure);
    this.loaded();
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    formatNumber(number) {
      return formatPrice(number);
    },
    getLabelForAccess(maxAccess = 0) {
      return `\(Máximo ${maxAccess} ${maxAccess > 1 ? 'accesos' : 'acceso'} por compra\)`
    },
    hideAccesscounter(eventId, ticket) {
      let finalHideAccess = false;

      if (ticket.hidAccess || this.hideEventAccess.includes(eventId)) {
        finalHideAccess = false;
      } else {
        finalHideAccess = true;
      }

      return finalHideAccess;
    },
    getValueInOrgData(arrayObject, id) {
      let findIndex = arrayObject.findIndex((item) => item.id == id);
      console.log(arrayObject[findIndex]);
      return arrayObject[findIndex];
    },
    async getMethodPaymentActive(userID) {
      const response = await this.axios.get(
        Endpoints.excecuteGetMethodPayment.replace(/:userID/, userID)
      );
      const { data } = response;
      this.hasStripeConfig = this.getValueInOrgData(
        data.methods,
        "bank_transfer_payments"
      ).active;
    },
    executivesFiltered(executives) {
      const ninguno = ["Ninguno"];
      if (this.searchExecutive.trim() == "") {
        return ninguno.concat(executives);
      }
      return executives.filter((executive) =>
        executive.toLowerCase().includes(this.searchExecutive.toLowerCase())
      );
    },
    validateTicketsForm() {
      if (this.$refs.ticketsForm.validate()) {
        this.currentStep++;
      }
    },
    setPaymentMethod(paymentName) {
      const { Cash, CreditCard, BankTransfer } = this.paymentMethodsKeys;
      const cases = {
        [Cash]: "Efectivo (Oxxo)",
        [CreditCard]: "Tarjeta de Crédito/Débito",
        [BankTransfer]: "Transferencia Bancaria SPEI",
      };
      return cases[paymentName] || "";
    },
    validate3sSecure: async function (e) {
      if (e.data === "3DS-authentication-complete") {
        console.log("Hola");
        this.iframeDialog = false;

        //this.loading();

        const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
          stripeAccount: this.stripeAccount,
        });

        // Check the PaymentIntent
        console.log(this.clientSecret);

        await stripe.retrievePaymentIntent(this.clientSecret).then((result) => {
          if (result.error) {
            this.errorMessage =
              "El pago falló porque el banco emisor lo rechazó. Comunicate con el banco emisor para obtener más información.";
            this.errorCode = "";
            this.isOpenErrorCardModal = true;
            this.currentStep--;
            this.stripeComponentKey++;
            this.order.paymentInformation.cardName = "";
            this.termsOfUSe.terms = false;
            this.billingPolitics.terms = false;
            this.source = "";
            this.loaded();
          } else {
            if (result.paymentIntent.status === "succeeded") {
              this.show({
                text: "Registro realizado correctamente",
                color: "primary",
              });
              this.currentStep++;
              this.loaded();
            } else if (
              result.paymentIntent.status === "requires_payment_method"
            ) {
              this.errorMessage = "¡No se ha podido realizar la transacción!";
              this.errorCode = "";
              this.isOpenErrorCardModal = true;
              this.currentStep--;
              this.stripeComponentKey++;
              this.order.paymentInformation.cardName = "";
              this.termsOfUSe.terms = false;
              this.billingPolitics.terms = false;
              this.source = "";
              this.loaded();
            }
          }
        });
      }
    },
    formatTickets(tickets) {
      return tickets;
    },
    async setEventInfo() {
      try {
        const response = await this.axios.get(
          Endpoints.checkoutEvent.replace(/:eventId/, this.eventId)
        );

        if (response.status > 199 && response.status < 300) {
          const {
            name,
            tickets,
            ticketsPerOrder,
            paymentMethods,
            banner,
            paymentModality,
            published,
            organization: { stripeConnectId, id, stripeAccountType },
            micrositeConfiguration: {
              primaryColor,
              buttonColor,
              textButtonColor,
            },
          } = response.data.checkoutInformation;

          this.eventExists = true;
          this.eventName = name;
          this.paymentModality = paymentModality;
          this.published = published;
          this.tickets = this.formatTickets(tickets);
          this.maxTicketsUserCanBuy = ticketsPerOrder;
          this.stripeAccount =
            stripeAccountType == "STANDARD" ? stripeConnectId : null;
          this.organizationId = id;
          // Filtrar los métodos de pago
          this.paymentMethods = paymentMethods.filter(
            (method) => method.active == true
          );
          this.msConfig = {
            banner,
            primaryColor,
            buttonColor,
            textButtonColor,
          };
          await this.getMethodPaymentActive(
            response.data.checkoutInformation.organization._id
          );
        }
      } catch (error) {
        // this.$router.push({ name: '404' });
        console.log("--------------------");
        console.log(error);
      }
    },
    async setTickets() {
      try {
        const response = await this.axios.get(
          Endpoints.checkoutTicket.replace(/:eventId/, this.eventId)
        );
        if (response.status === 200) {
          const { data } = response;
          this.tickets = this.formatTickets(data.tickets);
        }
      } catch ({ message }) { }
    },
    async createOrder() {
      try {
        const response = await this.axios.post(Endpoints.checkoutCreateOrder, {
          order: { event: this.eventId },
        });
        if (response.status === 200) {
          this.order.id = response.data.order.id;
        }
      } catch ({ message }) { }

      console.log("------------d----------");
      console.log(this.order);
      console.log("------------t----------");
      console.log("HOLA", this.tickets[0].event);
    },
    /**
     * @param {string} ticketId - Ticket id to find in tickets array
     * @returns {Object} Ticket object with id, quantity, name, modality, price, etc.
     */
    getTicketById: (ticketId, ticketsArray) =>
      ticketsArray.find((ticket) => ticket.id === ticketId),
    getTicketQuantityById(ticketId, ticketsArray) {
      return this.getTicketById(ticketId, ticketsArray).quantity;
    },
    async isTicketAvailable(ticketId) {
      const { quantity } = this.getTicketById(ticketId, this.tickets);
      if (quantity > 0) {
        this.setTickets();
        const { quantity: availableQty } = this.getTicketById(
          ticketId,
          this.tickets
        );
        if (availableQty > 0) return true;
      }
      return false;
    },
    getExtraInfo(registrationFields) {
      const extraFields = {};
      registrationFields.forEach((field) => {
        extraFields[field.formattedName] = null;
      });
      return extraFields;
    },
    async addTicket({ id, price, name }) {
      if (
        this.isTicketAvailable(id) &&
        this.ticketsToBuy < this.maxTicketsUserCanBuy
      ) {
        try {
          this.loading();
          const response = await this.axios.post(
            Endpoints.checkoutSetTicketToOrder,
            {
              item: {
                order: this.order.id,
                ticket: id,
              },
            }
          );

          if (response.status === 200) {
            const {
              tickets,
              order: { items },
            } = response.data;
            this.tickets = this.formatTickets(tickets);
            this.ticketsToBuy++;
            this.panel.push(this.panel.length);
            this.order.coupon.percentOff = 0;
            this.coupon = "";
            this.couponApplied = false;
            this.setSuccessToInitial("coupon");
            this.success.coupon.success = false;

            const ticket = this.getTicketById(id, this.order.items);
            const [itemToPush] =
              items.filter(
                (item) =>
                  !this.order.tickets.find(
                    (ticket) => ticket.itemId === item._id
                  )
              ) || [];
            const {
              ticket: { registrationFields },
            } = itemToPush;
            const extraInfo =
              registrationFields?.length > 0
                ? this.getExtraInfo(registrationFields)
                : {};

            this.order.tickets.push({
              id,
              itemId: itemToPush._id,
              price,
              name,
              registrationFields: registrationFields || [],
              extraInfo,
              attendeeInformation: {
                name: "",
                lastName: "",
                email: "",
                confirmEmail: "",
              },
            });

            if (ticket) {
              this.order.items = [
                ...this.order.items.map((item) =>
                  item.id === id
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
                ),
              ];
            } else {
              this.order.items.push({
                id,
                name,
                price,
                quantity: 1,
              });
            }
          }
        } catch (error) {
          console.log("**************");
          console.log(error);
        } finally {
          this.loaded();
        }
      }
    },
    async removeTicket(ticketId) {
      const anyMatchingTicket = this.order.tickets.some(
        (ticket) => ticket.id === ticketId
      );
      try {
        this.loading();
        if (this.ticketsToBuy !== 0 && anyMatchingTicket) {
          const response = await this.axios.delete(
            Endpoints.checkoutSetTicketToOrder,
            {
              data: {
                item: {
                  order: this.order.id,
                  ticket: ticketId,
                },
              },
            }
          );
          if (response.status === 200) {
            let { items } = this.orderState;
            const { tickets } = response.data;
            this.tickets = this.formatTickets(tickets);
            items = items
              .map((ticket) =>
                ticket.id === ticketId
                  ? { ...ticket, quantity: ticket.quantity-- }
                  : ticket
              )
              .filter((ticket) => ticket.quantity === 0);
            const ticketIndexToDelete = this.order.tickets.findIndex(
              (ticket) => ticket.id === ticketId
            );
            ticketIndexToDelete !== -1 &&
              this.order.tickets.splice(ticketIndexToDelete, 1);
            this.panel.splice(-1, 1);
            this.ticketsToBuy--;
            this.order.coupon.percentOff = 0;
            this.coupon = "";
            this.couponApplied = false;
            this.setSuccessToInitial("coupon");
            this.success.coupon.success = false;
          }
        }
      } catch (error) {
        console.log("**************");
        console.log(error);
      } finally {
        this.loaded();
      }
    },
    async addCoupon() {
      try {
        this.loading();
        const couponCode = this.coupon.trim();

        if (couponCode) {
          const response = await this.axios.post(Endpoints.couponValidate, {
            coupon: {
              tickets: this.tickets,
              id: this.id,
              event: this.eventId,
              code: couponCode,
              order: this.order.id,
            },
          });

          if (response.status === 200) {
            const { coupon } = response.data;
            this.order.coupon = coupon;
            this.setErrorToInitial("coupon");

            const orderIds = this.order.tickets.map((ticket) => ticket.id);
            const couponTickets = this.order.coupon.tickets.map(
              (ticket) => ticket
            );
            //console.log("order");
            //console.log(orderIds);
            // console.log("cupon");
            // console.log(couponTickets);

            if (couponTickets != "") {
              const allIdsPresent = orderIds.every((id) =>
                couponTickets.includes(id)
              );
              console.log("lleno");
              if (allIdsPresent) {
                this.success.coupon = {
                  success: true,
                  message: `${coupon.percentOff}% de descuento, se aplicará al finalizar la compra.`,
                };
                this.couponApplied = true;
              } else {
                this.order.coupon.percentOff = 0;
                this.setSuccessToInitial("coupon");
                this.errors.coupon = {
                  error: true,
                  message: "El cupón no es válido",
                };
              }
            } else {
              this.success.coupon = {
                success: true,
                message: `${coupon.percentOff}% de descuento, se aplicará al finalizar la compra.`,
              };
              this.couponApplied = true;
            }

            //console.log(allIdsPresent);
            ///
          }
        } else {
          this.setSuccessToInitial("coupon");
          this.errors.coupon = {
            error: true,
            message: "Ingrese el código del cupón",
          };
        }
      } catch (error) {
        const response = error.response;
        this.setSuccessToInitial("coupon");
        this.errors.coupon = {
          error: true,
          message:
            response && response.data && response.data.error
              ? response.data.error.message
              : "El cupón no es válido",
        };
      } finally {
        this.loaded();
      }
    },

    paymentSelection(toggle, paymentName) {
      if (paymentName == "CREDIT_CARD") {
        this.cardPaymentIntentId = "";
      }
      this.order.paymentInformation.paymentMethod = paymentName;
      toggle();
    },
    setErrorToInitial(errorKey) {
      if (this.errors[errorKey].error) {
        this.errors[errorKey] = {
          error: false,
          message: "",
        };
      }
    },
    setSuccessToInitial(successKey) {
      if (this.success[successKey].success) {
        this.success[successKey] = {
          success: true,
          message: "",
        };
      }
    },
    formatEmail(ticket) {
      ticket.attendeeInformation.email = ticket.attendeeInformation.email
        .toLowerCase()
        .trim();
    },
    showFacturaDialog() {
      if (!this.factura) {
        this.factura = true;
        this.facturaDialog = true;
      } else {
        this.factura = false;
      }
    },
    async setAttendeeInformation() {
      if (!this.$refs.ticketsForm.validate()) return;
      let items = [];

      for (const ticket of this.order.tickets) {
        let extraInfoFiles = new FormData();
        extraInfoFiles.append("order", this.order.id);
        extraInfoFiles.append("item", ticket.itemId);
        let extraInfoWithoutFiles = {};
        Object.keys(ticket.extraInfo).forEach((key) => {
          if (!(ticket.extraInfo[key] instanceof File)) {
            extraInfoWithoutFiles[key] = ticket.extraInfo[key];
          } else {
            extraInfoFiles.append(key, ticket.extraInfo[key]);
          }
        });
        items.push({
          _id: ticket.itemId,
          attendeeInformation: {
            name: ticket.attendeeInformation.name,
            lastName: ticket.attendeeInformation.lastName,
            email: ticket.attendeeInformation.email,
            ...extraInfoWithoutFiles,
          },
        });

        try {
          await this.axios.post(
            Endpoints.checkoutSetAttendeeDocs,
            extraInfoFiles
          );
        } catch (error) {
          console.log("******************");
          console.log(error);
        }
      }

      const payload = {
        order: {
          id: this.order.id,
          items,
        },
      };
      try {
        this.loading();
        await this.axios.post(
          Endpoints.checkoutSetAttendeeInformation,
          payload
        );

        console.log("Attendeee information");
        console.log(this.order);
      } catch (error) {
        console.log("******************");
        console.log(error);
      } finally {
        this.loaded();
      }

      this.total = this.totalPurchase.number;
      // Comprobar si se usó un cupón del 100% de descuento para avanzar al último paso del checkout
      if (this.order.coupon.percentOff == 100 || this.paymentModality) {
        this.order.paymentInformation.paymentMethod = "FREE";
        this.currentStep = this.currentStep + 2;
      } else {
        this.currentStep++;
      }
    },
    async updateCoupon() {
      const { id: _, ...couponRest } = this.order.coupon;
      let updatedApplications = this.order.coupon.applications;

      if (this.order.coupon.applications != null) {
        updatedApplications = this.order.coupon.applications + 1;
      }
      let updatedStatus = "ACTIVE";
      if (this.order.coupon.limit != null) {
        if (updatedApplications >= this.order.coupon.limit) {
          updatedStatus = "EXPIRED";
        }
      }

      const data = {
        coupon: {
          ...couponRest,
          applications: updatedApplications,
          status: updatedStatus,
        },
      };

      const response = await this.axios.put(
        Endpoints.couponsId.replace(":id", this.order.coupon.id),
        data
      );

      if (response.status === 200) {
        const { coupon: responseCoupon } = response.data;

        // Aquí actualizamos la variable coupon si el ID coincide
        if (this.order.coupon.id === responseCoupon.id) {
          this.order.coupon = responseCoupon;
        }
      }
    },
    async finishBuying() {
      // Obtener métodos de pago disponibles
      const { Free, Cash, CreditCard, BankTransfer } = this.paymentMethodsKeys;
      // Obtener el método de pago usado en la orden
      const { paymentMethod } = this.order.paymentInformation;
      // Relacionar la función que se va a ejecutar con el nombre del método de pago
      const cases = {
        [Free]: this.excecuteFreeRegistration,
        [Cash]: this.executeCashPayment,
        [CreditCard]: this.executeCardPayment,
        [BankTransfer]: this.executeBankTransferPayment,
      };
      //console.log(this.order.coupon)

      if (this.order.coupon.id) {
        await this.updateCoupon();
      }
      // Comprobar que existe el método de pago y ejecutar la función que le corresponde a ese método de pago
      cases[paymentMethod] && (await cases[paymentMethod]());
    },
    async excecuteFreeRegistration() {
      this.loading();
      try {
        let response = await this.axios({
          method: "post",
          url: Endpoints.excecuteFreeRegistration,
          json: true,
          data: {
            order: {
              orderId: this.order.id,
              eventId: this.eventId,
              paymentInformation: {
                name:
                  this.order.tickets[0].attendeeInformation.name +
                  " " +
                  this.order.tickets[0].attendeeInformation.lastName,
                email: this.order.tickets[0].attendeeInformation.email,
              },
            },
          },
        });
        console.log("Respuesta al registrar una transacción gratuita");
        console.log(response);
        this.currentStep++;
      } catch (error) {
        console.log("Respuesta del error");
        console.log(error);
        this.currentStep = this.currentStep - 2;
      } finally {
        this.loaded();
      }
    },
    closeErrorCardModal() {
      this.isOpenErrorCardModal = false;
      this.errorMessage = "";
      this.errorCode = "";
    },
    /**
     * Ejecutar cuando se usa el método de pago de tarjeta para finalizar todos los pasos del checkout
     */
    async executeCardPayment() {
      try {
        this.loading();
        let response = await this.axios({
          method: "post",
          url: Endpoints.executeCardPayement,
          json: true,
          data: {
            payment: {
              paymentIntentId: this.cardPaymentIntentId,
              selectedPlan: this.installments.selected,
              connectedAccount: this.stripeAccount,
            },
          },
        });

        await this.setOrderPaymentInformation();

        if (
          response.data.payment.next_action &&
          response.data.payment.next_action.redirect_to_url.url
        ) {
          this.iframeDialog = true;
          this.clientSecret = response.data.payment.client_secret;
          this.iframeSource =
            response.data.payment.next_action.redirect_to_url.url;
        } else {
          this.show({
            text: "Registro realizado correctamente",
            color: "primary",
          });
          this.currentStep++;
        }
      } catch (error) {
        console.log("Estoy respondiendo con el error");
        console.log(error);

        console.log(error.response.data);
        this.errorMessage = "";
        if (error.declineCode && CardErrors[error.declineCode]) {
          this.errorMessage = CardErrors[error.declineCode];
          this.errorCode = error.declineCode;
        } else if (error.code && CardErrors[error.code]) {
          this.errorMessage = CardErrors[error.code];
          this.errorCode = error.code;
        } else {
          this.errorMessage =
            "El pago falló porque el banco emisor lo rechazó. Comunicate con el banco emisor para obtener más información.";
          this.errorCode = "";
        }
        this.isOpenErrorCardModal = true;
        this.currentStep--;
        this.stripeComponentKey++;
        this.order.paymentInformation.cardName = "";
        this.termsOfUSe.terms = false;
        this.billingPolitics.terms = false;
        this.source = "";
      } finally {
        this.loaded();
      }
    },
    /**
     * Ejecutar en el paso 3 del checkout
     * Solo se ejecuta una acción diferente cuando se usa el método de pago de oxxo
     */
    async executePaymentAction() {
      await this.setOrderPaymentInformation();

      this.currentStep++;
    },
    /**
     * Ejecutar cuando el usuario se quiere regresar del paso 4 al paso 2
     * Verifica si el evento es gratuito o el usuario usó un cupón del 100% para saber si se regresa al paso 2, saltandose el paso 3 del checkout
     */
    returnFromStepFour() {
      if (this.order.coupon.percentOff == 100 || this.paymentModality) {
        this.currentStep = this.currentStep - 2;
      } else {
        this.currentStep--;
      }
    },
    /**
     * Ejecutar cuando se usa el método de pago de oxxo para finalizar todos los pasos del checkout
     */
    async executeCashPayment() {
      try {
        this.loading();

        let response = await this.axios({
          method: "post",
          url: Endpoints.cashPaymentIntent,
          json: true,
          data: {
            paymentMethod: { total: this.total },
            orderId: this.order.id,
          },
        });
        this.clientSecret = response.data.paymentIntent.clientSecret;

        let stripe;
        if (this.stripeAccount) {
          stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
            stripeAccount: this.stripeAccount,
          });
        } else {
          stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
        }
        //await this.setOrderPaymentInformation();

        const result = await stripe.confirmOxxoPayment(this.clientSecret, {
          payment_method: {
            billing_details: {
              name: `${this.order.paymentInformation.name} ${this.order.paymentInformation.lastName}`,
              email: this.order.paymentInformation.email,
            },
          },
        });
        if (result.error) {
          console.log("MENSAJE DE ERROR");
          console.log(result.error);
          this.show({
            text: "¡No se ha podido realizar la transacción!",
            color: "error",
          });
        } else {
          console.log("stripe result", result);
          await this.axios({
            method: "PUT",
            url: `/orders/set-payment-reference/${this.order.id}`,
            data: {
              paymentReference:
                result.paymentIntent.next_action.oxxo_display_details
                  .hosted_voucher_url,
            },
          });
          this.show({
            text: "Registro realizado correctamente",
            color: "primary",
          });
        }
        this.currentStep++;
      } catch (error) {
        this.show({
          text:
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "¡No se ha podido realizar la transacción!",
          color: "error",
        });
      } finally {
        this.loaded();
      }
    },
    async executeBankTransferPayment() {
      try {
        this.loading();

        //await this.setOrderPaymentInformation();

        let response = await this.axios({
          method: "post",
          url: Endpoints.bankTransferPaymentIntent,
          json: true,
          data: {
            paymentMethod: { total: this.total },
            customer: {
              name: `${this.order.paymentInformation.name} ${this.order.paymentInformation.lastName}`,
              email: this.order.paymentInformation.email,
            },
            orderId: this.order.id,
          },
        });

        this.clientSecret = response.data.paymentIntent.clientSecret;

        let stripe;
        if (this.stripeAccount) {
          stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
            stripeAccount: this.stripeAccount,
          });
        } else {
          stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
        }

        // const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
        //   stripeAccount: this.stripeAccount,
        //   apiVersion: "2020-08-27;customer_balance_payment_method_beta=v2",
        //   betas: ["customer_balance_beta_1"],
        // });
        let isAmpi = ["62b622c66a7441ae20159d7d", "6297b0fec7bf8b6f826313be"].includes(this.organizationId)


        const result = await stripe.confirmCustomerBalancePayment(
          this.clientSecret,
          {
            payment_method: {
              billing_details: {
                name: `${this.order.paymentInformation.name} ${this.order.paymentInformation.lastName}`,
                email: this.order.paymentInformation.email,
              },
            },
            // payment_method_options: {
            //   customer_balance: {
            //     funding_type: "bank_transfer",
            //     bank_transfer: {
            //     },
            //   },
            // },
          },
          {
            handleActions: !isAmpi,
          }
        );


        if (result.error) {
          console.log("MENSAJE DE ERROR");
          console.log(result.error);
          this.show({
            text: "¡No se ha podido realizar la transacción!",
            color: "error",
          });
        } else {
          await this.axios({
            method: "PUT",
            url: `/orders/set-payment-reference/${this.order.id}`,
            data: {
              paymentReference:
                result.paymentIntent.next_action
                  .display_bank_transfer_instructions.hosted_instructions_url,
            },
          });
          this.show({
            text: "Registro realizado correctamente",
            color: "primary",
          });
          if (isAmpi) {
            this.voucherDialog = true
            this.bankTransferInstructions = result.paymentIntent.next_action.display_bank_transfer_instructions
            this.bankTransferDescription = result.paymentIntent.description ? result.paymentIntent.description : this.order.id
          }
        }
        if (!isAmpi) this.currentStep++;
      } catch (error) {
        this.show({
          text:
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "¡No se ha podido realizar la transacción!",
          color: "error",
        });
      } finally {
        this.loaded();
      }
    },

    closeVoucherDialog() {
      this.voucherDialog = false,
        this.currentStep++
    },

    async setOrderPaymentInformation() {
      this.order.paymentInformation.needsInvoice = this.acceptBill;
      console.log(this.order.paymentInformation.needsInvoice);
      try {
        this.loading();
        await this.axios({
          method: "post",
          url: Endpoints.checkoutSetPaymentInformation,
          json: true,
          data: {
            order: {
              id: this.order.id,
              paymentInformation: this.order.paymentInformation,
            },
          },
        });
      } catch (error) {
        console.log("******************");
        console.log(error);
      } finally {
        this.loaded();
      }
    },
    triggerErrorAlert(error) {
      this.snackbar = true;
      this.snackbarText = error.message;
      this.source = "";
      this.cardPaymentIntentId = "";
      this.stripeComponentKey++;
    },
    routeValidation(route) {
      const regexpresion = new RegExp("ampi", "i");
      const regexpresion1 = new RegExp(
        "Asociación Mexicana de Profesionales Inmobiliarios",
        "i"
      );
      return regexpresion.test(route) || regexpresion1.test(route);
    },
  },
  computed: {
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("user", ["user"]),
    dateExpireOrder() {
      let expireIn = moment().set("hour", 72);
      return `${expireIn.format("h:mm")} hrs del dia ${expireIn.get(
        "date"
      )} de ${expireIn.format("MMMM")}`;
    },
    orderState() {
      return {
        items: this.order.items,
        hasItems: this.order.items.length > 0,
      };
    },
    discount() {
      const total = this.totalPurchase.number;
      const discount = total * (this.order.coupon.percentOff / 100);
      return {
        string: this.formatNumber(discount),
        number: discount,
      };
    },
    totalPurchase() {
      let total = 0;
      const { items, hasItems } = this.orderState;
      hasItems &&
        items.forEach(({ price, quantity }) => {
          total += price * quantity;
        });
      return {
        string: this.formatNumber(total),
        number: total,
      };
    },
    totalPurchaseWithDiscount() {
      const total = this.totalPurchase.number;
      const discount = this.discount.number;
      const totalWithDiscount = total - discount;
      return {
        string: this.formatNumber(totalWithDiscount),
        number: totalWithDiscount,
      };
    },
    disableContinueBuyButton() {
      const { name, lastName, email, paymentMethod } =
        this.order.paymentInformation;

      let commonRules;

      if (
        ["62b622c66a7441ae20159d7d", "6297b0fec7bf8b6f826313be"].includes(
          this.organizationId
        )
      ) {
        commonRules =
          !this.termsOfUSe.terms ||
          !this.billingPolitics.terms ||
          !lastName ||
          !email ||
          !name ||
          !paymentMethod;
      } else {
        commonRules =
          !this.termsOfUSe.terms ||
          !lastName ||
          !email ||
          !name ||
          !paymentMethod;
      }

      const creditCardRules =
        paymentMethod === this.paymentMethodsKeys.CreditCard &&
        !this.cardPaymentIntentId;
      return (
        commonRules ||
        creditCardRules ||
        !(this.acceptBill == true || this.declineBill == true)
      );
    },
    counterValues() {
      const counter = {};
      const { items, hasItems } = this.orderState;
      hasItems &&
        items.forEach(({ id, quantity }) => {
          counter[id] = quantity;
        });
      return counter;
    },
    formasDePago() {
      return [
        "01 Efectivo",
        "03 Transferencia electrónica de fondos ( Incluye SPEI)",
        "04 Tarjeta de crédito",
        "28 Tarjeta de débito",
      ];
    },
    ticketsToBuyIsZero() {
      return this.ticketsToBuy === 0;
    },
    /**
     * Función que toma el tamaño de la pantalla del dispositivo para dar tamaño a los iconos (v-dialog de facturación).
     */
    getBigSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:2.5em";
        case "sm":
          return "font-size:2.7em";
        case "md":
          return "font-size:2.7em";
        case "lg":
          return "font-size:3em";
        case "xl":
          return "font-size:3.2em";
      }
    },
    /**
     * Función que toma el tamaño de la pantalla del dispositivo para dar tamaño a los iconos de métodos de pago
     */
    getSizePaymentsMethodsIcons() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:2.5em";
        case "sm":
          return "font-size:2.5em";
        case "md":
          return "font-size:2.3em";
        case "lg":
          return "font-size:2.3em";
        case "xl":
          return "font-size:2.5em";
      }
    },
    getSizeFinalIcon() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:4.5em";
        case "sm":
          return "font-size:4.5em";
        case "md":
          return "font-size:5em";
        case "lg":
          return "font-size:6.3em";
        case "xl":
          return "font-size:6.5em";
      }
    },
  },
};
</script>
<style scoped>
.relative-left {
  position: relative;
  left: 0%;
}

.texto-rojo {
  color: red;
}

.access-item-border {
  border: 1.2px solid !important;
  border-color: rgb(241, 241, 241) !important;
}

.label_move {
  position: relative;
  display: inline-block;
  animation: 4s linear 0s infinite alternate move;
  -webkit-transition-duration: 4s;
}

@keyframes move {
  0%,
  25% {
    transform: translateX(0%);
    left: 0%;
  }

  75%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}
</style>
